Object.assign||Object.defineProperty(Object,"assign",{enumerable:!1,configurable:!0,writable:!0,value:function(e){"use strict";if(null==e)throw new TypeError("Cannot convert first argument to object");for(var r=Object(e),t=1;t<arguments.length;t++){var n=arguments[t];if(null!=n){n=Object(n);for(var c=Object.keys(Object(n)),o=0,a=c.length;o<a;o++){var b=c[o],i=Object.getOwnPropertyDescriptor(n,b);void 0!==i&&i.enumerable&&(r[b]=n[b])}}}return r}});
export default class {
  constructor(){
    var self=this;
    self.isModal = false;
    self.init();
    ROOT.sixMonthLater = new Date();
    ROOT.sixMonthLater.setMonth(ROOT.sixMonthLater.getMonth() + 6);
  }
  init(){
    var self=this;
    self.$wrap=$(".wrap");
    self.current_scrollY=0;
    self.$scrollTarget=$( document );
    self.registration();
    self.login();
    self.search();
    self.picList();

    $('.wrap .modal-contents').each(function(i,el) {
      $(".modal .modal-inner").append($(el))
    })
    $(document).on('click','.btn-modal-open',function(){
      var target = $(this).attr('data-modal')
      var scene = $(this).attr('data-scene')
      if (target=='pic-list') {
        var parent = $(this).parent("li")
        var index = $(parent).index();
        var el = $("#"+target).find(".pic-list-slider")
        UIkit.slider(el, {index:index});
        //UIkit.slider(el).show(index);
      }
      self.loginhVue.isOpen=false;
      self.registrationVue.isOpen=false;
      self.searchVue.isOpen=false;
      if (ROOT.commonHeader.isOpen) {
        ROOT.commonHeader.close()
        setTimeout(function(){
          self.modalOpen(target,scene)
        },100)
      }
      else{
        self.modalOpen(target,scene)
      }
      return false;
    });
    $('.btn-modal-close').on('click',function(){
      var target = $(this).attr('data-modal')
      self.modalClose(target)
      return false;
    });
    if (ROOT.urlParaArr.registrationError) {
      self.modalOpen('member-registration')
    }
    if (ROOT.urlParaArr.loginError) {
      self.modalOpen('member-login')
    }
  }
  modalOpen(str,str2){
    var self=this;
    self.isModal = true;
    ROOT.scrollControll.stop()
    if (str=="member-registration") {
      self.registrationVue.isOpen=true;
      $('.modal').removeClass('modal-search');
    }
    if (str=="member-login") {
      self.loginhVue.isOpen=true;
      $('.modal').removeClass('modal-search');
    }
    if (str=="search-modal") {
      self.searchVue.isOpen=true;
      self.searchVue.scene=str2;
      $('.modal').addClass('modal-search');
    }
    if (str=="pic-list") {
      self.picListVue.isOpen=true;
      $('.modal').removeClass('modal-search');
    }
    if (str=="kiyaku-modal") {
      self.kiyakuVue.isOpen=true;
      $('.modal').removeClass('modal-search');
    }
    $('.modal').addClass('modalOpen');
    setTimeout(function(){
      self.$scrollTarget.scrollTop(0);
      $('#'+str).addClass('modalContentsOpen')
    },400)
    var winH = $(window).height();
    if ($(".modal-contents").outerHeight() >  winH) {
      $('.modal').addClass('flex-top');
    }
  }
  modalClose(str){
    var self=this;
    self.isModal = false;
    ROOT.scrollControll.move()
    $('.modal').addClass('modalClose').removeClass('modalOpen');
    if (str) {
      $('#'+str).addClass('modalContentsClose').removeClass('modalContentsOpen');
      setTimeout(function(){
        $('#'+str).removeClass('modalContentsClose');
        $('.modal').removeClass('modalClose flex-top');
        self.searchVue.isOpen=false;
      },600)
    }
    else{
      $('.modal-contents').addClass('modalContentsClose').removeClass('modalContentsOpen');
      setTimeout(function(){
        $('.modal-contents').removeClass('modalContentsClose');
        $('.modal').removeClass('modalClose flex-top');
        self.searchVue.isOpen=false;
      },600)
    }
  }
  registrationCheck(){
    var self=this;
    $('.member-registration-form').submit(function(){
      var len = $('.member-registration-form input').length;
      for (var i = 0;  i < len;  i++) {
        var val = $('.member-registration-form input').eq(i).val();
        if (!val) {
          $('.registration-input-error-text').text('未入力の項目があります。')
          return false;
        }
      }
    });
    $('.member-registration-form input').each(function(i,el){
      $(el).on('input',function(event){
        var val = $(el).val();
        if (val) {$('.registration-input-error-text').text('');}
      })
    })
  }
  registration(){
    var self = this;
    self.registrationData = {}
    $('.uk-input, .uk-select, .uk-radio, .uk-checkbox, .uk-textarea','#member-registration').each((i,el)=>{
      var $el = $(el);
      $el.attr("v-model","registrationData."+el.name);
      if ($el.attr('data-any-item')) {
        if (self.anyItemList2.indexOf(el.name) == -1) {
          self.anyItemList2.push(el.name);
        }
      }
      if($el.hasClass("uk-checkbox") && !self.registrationData[el.name]){self.registrationData[el.name]=[]};
      if($el.hasClass("uk-radio") && $el.prop("checked")==false){
        return true;
      }else if($el.hasClass("uk-checkbox") && $el.prop("checked")==false){
        return true;
      }else{
        if($el.val()===null){
          self.registrationData[el.name]="";
        }else if(typeof self.registrationData[el.name]=="object"){
          self.registrationData[el.name].push($el.val());
        } else {
          self.registrationData[el.name]=$el.val();
        }
      }
    });

    self.registrationVue = new Vue({
      delimiters: ['[[',']]'],
      el: "#member-registration",
      data:{
        isOpen:false,
        registrationData:Object.assign({}, self.registrationData),
        notEnteredError:false,
        notregistrationError:false,
        notregistrationError:false,
        registrationError:false,
        registrationErrorText:'',
        validation_email:false,
      },
      computed:{
      },
      watch:{
      },
      created(){
        var self = this;
        if (ROOT.urlParaArr.registrationError) {
          self.registrationError = true;
          self.registrationErrorText = decodeURI(ROOT.urlParaArr.registrationError);
        }
      },
      mounted(){
        var self = this;
      },
      methods:{
        close(){
          var self = this;
        },
        checkForm(){
          var self = this;
          self.notEnteredError = false;
          if (!self.registrationData.email.length) {
            self.notEnteredError = true;
          }
          if (!self.notEnteredError && !self.validation_email) {
            $('.member-registration-form').submit();
          }
        },
        emailCheck(){
          var self = this;
          if (ROOT.utils.validationEmail(self.registrationData.email)) {
            self.validation_email = false;
          }
          else{
            self.validation_email = true;
          }
        },
      }
    })
  }
  login(){
    var self = this;
    self.loginData = {}
    $('.uk-input, .uk-select, .uk-radio, .uk-checkbox, .uk-textarea','#member-login').each((i,el)=>{
      var $el = $(el);
      $el.attr("v-model","loginData."+el.name);
      if ($el.attr('data-any-item')) {
        if (self.anyItemList2.indexOf(el.name) == -1) {
          self.anyItemList2.push(el.name);
        }
      }
      if($el.hasClass("uk-checkbox") && !self.loginData[el.name]){self.loginData[el.name]=[]};
      if($el.hasClass("uk-radio") && $el.prop("checked")==false){
        return true;
      }else if($el.hasClass("uk-checkbox") && $el.prop("checked")==false){
        return true;
      }else{
        if($el.val()===null){
          self.loginData[el.name]="";
        }else if(typeof self.loginData[el.name]=="object"){
          self.loginData[el.name].push($el.val());
        } else {
          self.loginData[el.name]=$el.val();
        }
      }
    });
    self.loginhVue = new Vue({
      delimiters: ['[[',']]'],
      el: "#member-login",
      data:{
        loginData:Object.assign({}, self.loginData),
        isOpen:false,
        notEnteredError:false,
        loginError:false,
        loginErrorText:'',
        validation_email:false,
      },
      created(){
        var self = this;
        if (ROOT.urlParaArr.loginError) {
          self.loginError = true;
          self.loginErrorText = decodeURI(ROOT.urlParaArr.loginError);
        }
      },
      mounted(){
        var self = this;
        if (self.loginData.email) {
          self.emailCheck();
        }
      },
      methods:{
        close(){
          var self = this;
        },
        checkForm(){
          var self = this;
          self.notEnteredError = false;
          if (!self.loginData.email.length || !self.loginData.password.length) {
            self.notEnteredError = true;
          }
          if (!self.notEnteredError && !self.validation_email) {
            $('.member-login-form').submit();
          }
        },
        emailCheck(){
          var self = this;
          if (ROOT.utils.validationEmail(self.loginData.email)) {
            self.validation_email = false;
          }
          else{
            self.validation_email = true;
          }
        },
      }
    });
  }
  search(){
    var self = this;
    self.searchData = {}
    $('.uk-input, .uk-select, .uk-radio, .uk-checkbox, .uk-textarea','#search-modal').each((i,el)=>{
      var $el = $(el);
      var name = el.name.replace("[]","");
      $el.attr("v-model","searchData."+name);
      if ($el.hasClass("uk-checkbox") && !self.searchData[name]) { self.searchData[name]=[]; }
      if ($el.hasClass("uk-checkbox")) {
        if ($el.prop("checked")) {
          self.searchData[name].push($el.val())
        }
      }
      else if(!$el.hasClass("uk-checkbox")){
        if ($el.val()) {
          self.searchData[name] = $el.val()
        }
        else{
          self.searchData[name]='';
        }
      }
    });

    self.searchVue = new Vue({
      delimiters: ['[[',']]'],
      el: "#search-modal",
      data:{
        searchData:Object.assign({}, self.searchData),
        isOpen:false,
        scene:null,
        mode: 'range',
        selectedDate: {
          start: new Date(self.searchData.start),
          end: new Date(self.searchData.end)
        },
        btnHeight:0,
      },
      computed:{
      },
      watch:{
        selectedDate:function(){
          this.searchData.start = moment(this.selectedDate.start).format("YYYY/MM/DD");
          this.searchData.end = moment(this.selectedDate.end).format("YYYY/MM/DD");
        },
        isOpen:function(bool){
          if (bool) {
            this.searchData = Object.assign({}, self.searchData);
            setTimeout(()=>{
              this.selectedDate.start = new Date(self.searchData.start)
              this.selectedDate.end = new Date(self.searchData.end)
            },1)
          }
        }
      },
      created(){
        var self = this;
      },
      mounted(){
        var self = this;
      },
      methods:{
        close(){
          var self = this;
        },
        checkForm(){
          var self = this;
        },
        keywordsCancel(){
          var self = this;
          self.searchData.keywords = '';
          return false;
        },
        keywordsChange(str){
          var self = this;
          self.searchData.keywords = str;
          return false;
        },
        searchSubmit(){
          var self = this;
          $("#search-modal .search-form").submit();
        }
      }
    })
  }
  picList(){
    var self=this;
    var picList = []
    $(".pic-list-holder li").each(function(i,el){
      var img = $(el).find("img")
      var item = {}
      item["title"] = img.attr("alt");
      item["src"] = img.attr("data-src");
      picList.push(item);
    })
    self.picListVue = new Vue({
      delimiters: ['[[',']]'],
      el: "#pic-list",
      data:{
        picListData:picList,
        isOpen:false,
      },
      created(){
        var self = this;
      },
      mounted(){
        var self = this;
      },
    });
  }
}


export default class {
  constructor(){
    var self=this;
    self.init();
  }
  init(){
    var self=this;
    self.setRecommendSlider();
  }
  setRecommendSlider(){

    $(".recommend-holder").each(function(i,el){
      var len = $(".recommend-holder .item").length;
      var next = $(el).find(".swiper-button-next")
      var prev = $(el).find(".swiper-button-prev")
      var option = {
        loopedSlides: len,
        slidesPerView: "auto",
        spaceBetween: 0,
        centeredSlides: true,
        loop: 'true',
        navigation: {
          nextEl: '.pic-list-navigation-item.right',
          prevEl: '.pic-list-navigation-item.left'
        },
      }
      var swiper = null;
      if(!ROOT.isSp){
        swiper = new Swiper(el,option);
      }
      ROOT.ev.on("ON_CHANGE_TARGET",function(n){
        if (ROOT.isSp && swiper) {
          swiper.destroy( true, true );
        }
        else if(!ROOT.isSp){
          swiper = new Swiper(el,option);
        }
      });
    });
  }
}


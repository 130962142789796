export default class {
  constructor(){
    var self = this;
    this.init();
  }
  init(){
    var self = this;
    self.addresSearch();
    self.topRecommendMore();
    self.chengeCardSelect();
    self.chengeSliderPic();
    self.btnPerMore();
    self.facilityBtnMore();
    self.facilityItems();
    // self.headerSearch();
    if ($("#register_confirm").length) {
      self.register_confirm()
    }
  }
  addresSearch(){
    $(".btn-search-address").click(function(){
        AjaxZip3.zip2addr('postal_code', '', 'prefecture', 'city');
    });
  }
  topRecommendMore(){
    $('.btn-more').on('click',function() {
      var $self = $(this)
      var parentSec = $self.parents('.section')
      if ($(parentSec).find('.item-hidden').length) {
        $(parentSec).find('.item-list-holder').removeClass('item-hidden');
        $(parentSec).find('.more-btn-holder').remove();
      }
    })
  }
  chengeCardSelect(){
    $("input[name=payment]").on("change",function(){
      var self = this;
      var label = $("input[name=payment]:checked").val();
      if(label){
        $(".select-payment input[data-payment-type='クレジットカード']").prop("checked",true).trigger("change")
        $('input[name=SECURITY_CD]').val("")
      }
      if(label=="ご登録のクレジットカード"){
        if(!$('.item-saved-credit input[name="payment"]:checked').length){
          $(".item-saved-credit input").prop("checked","checked").trigger("change");
        }
        $(".saved-credit-card").show()
        $('.item-new-credit .input-holder').hide()
      }else if(label=="新規のクレジットカード"){
        $("item-new-credit label").prop("checked","checked").trigger("change");
        $(".saved-credit-card").hide()
        $('.item-new-credit .input-holder').show()
      } else if(label=="現地決済"){
        $(".item-cash").prop("checked","checked").trigger("change");
        $(".saved-credit-card").hide()
        $('.item-new-credit .input-holder').hide()
      }
    });
  }
  chengeSliderPic(){
    $(".btn-pic picture").on("click",function(){
      var self = $(this).parent();
      var $btn = $(".btn-pic picture").parent();

      var mainPic = $(".pic-main img");
      var src = $(this).children('img').attr('src');
      mainPic.attr('src',src);

      $btn.removeClass('active');
      self.addClass('active');

    });
  }
  btnPerMore(){
    var moreNum = 6;
    var $btnMore = $('.button-area');
    // 検索結果一覧 もっと見るボタン 6件ずつ表示
    if($(".section-result").length){
      $('.facility-list-holder .item:nth-child(n + ' + (moreNum + 2) + ')').addClass('is-hidden');
      $('.button-area .btn').on('click', function() {
        $('.facility-list-holder .item.is-hidden').slice(0, moreNum).removeClass('is-hidden');
        if ($('.facility-list-holder .item.is-hidden').length == 0) {
          $btnMore.fadeOut();
        }
      });
    }
  }
  facilityBtnMore(){
    // もっと見るボタン押したとき
    $(".btnShowMore").on("click",function(i,e){
      $(this).addClass("hide");
      $(this).parent().addClass("open");
    });
    $(".btn-more-plan").on("click",function(i,e){
      $(this).addClass("hide");
      $(this).closest(".item").find(".plan-text-holder").addClass("open");
    });


    var $btnMore = $('.button-area');
    // 6件未満表示
    if($(".section-result").length){
      var moreNum = 6;
      var $itemList = $(".section-result .item-holder .item").length;
      if ( $itemList <= moreNum ){ $btnMore.hide();}
    } else if($(".facility-info-holder").length){
      var $itemList = $(".pic-list-holder .pic-list .btn").length;
      if ( $itemList <= moreNum ){ $btnMore.hide(); }
    }

    // TOP施設 3件未満
    if($(".section-facility").length){
      var moreNum = 3;
      var $btnMore = $("section-facility .button-area");
      var $itemRanking = $(".section-facility .item-holder .btn").length;
      if(ROOT.isSp){
        if ( $itemRanking <= moreNum + 1 ){ $btnMore.hide(); }
      } else {
        if ( $itemRanking <= moreNum ){ $btnMore.hide(); }
      }
    }

    // 施設詳細　トップテキストエリア
    if($(".section-facility-detail .top-text-holder").length){
      var $btnMore = $(".top-text-holder .button-area")
      var itemHeight = $(".text.intro").height();
      var textHeight = $(".text.intro .txt-info").height();
      if( textHeight < itemHeight ){ $btnMore.hide(); }
    }

    // 利用可能なプラン・この施設のすべてのプラン
    if($(".section-plan-all").length){
      var moreNum = 6;
      var $btnMore = $(".section-plan-all .btnShowMore")
      var $itemPlan = $(".section-plan-all .item-holder .item").length;
      if ( $itemPlan <= moreNum ){ $btnMore.hide(); }
    }
    if($(".section-plan-all .item-holder .item").length){
      var moreNum = 3;
      var $btnMore = $(".section-plan-all .btn-more-plan")
      var $itemPlan = $(".section-plan-all .item-holder .plan-text-inner").length;
      if ( $itemPlan <= moreNum ){ $btnMore.hide(); }
    }


  }
  facilityItems(){
    // 検索結果　0件のとき
    if ($(".section-result").length){
      var $item = $(".facility-holder .item").length;
      if( $item == 0 ){
        $(".facility-holder .no-items").removeClass("hide");
      }
    }
  }
  headerSearch(){
    // var self = this;
    // var pos = 0;
    // $(window).scroll(function(e){
    //   var currentPos = $(this).scrollTop();
    //   var $header = $(".header").scrollTop() + $(".header").height();
    //   var $seachHolder = $(".section-search");
    //   if (ROOT.isSp){
    //     if (currentPos > pos) {
    //       $seachHolder.removeClass("fix");
    //     } else {
    //       $seachHolder.addClass("fix");
    //     }
    //   } else {
    //     if (currentPos < $header) {
    //       $seachHolder.removeClass("fix");
    //     } else if (currentPos < pos) {
    //       $seachHolder.addClass("fix");
    //     }
    //   }
    //   pos = currentPos;
    // });
  }
  register_confirm(){

    var self = this;
    self.register_confirm = new Vue({
      delimiters: ['[[',']]'],
      el: "#register_confirm",
      data:{
        isKiyaku:false
      }
    });
  }
}
















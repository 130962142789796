export default class {
  constructor() {
    this.init();

  }

  init() {

    // var urlLis=[
    //   './txt/news-index-ja.txt'
    // ]
    // return Promise.all([
    //   axios({ /* NEWS 一覧                 ja */
    //     url:urlLis[0],responseType : 'text'
    //   }),

    // ]).then(result => {

    //   ROOT.ev.emit("API_LOAD_DONE")

    // }).catch( function ( reason ) {
    //   console.log('ERROR!! occurred in Backend.',reason);

    // })

    //ROOT.ev.on("CHANGE_SCENE",(o)=>{this.onChangeScene(o)})

  }


}



"use strict";
window.ROOT=window.ROOT||{};
ROOT.isDebug=location.search=="?debug";
if(location.href.indexOf("localhost")!==-1||location.href.indexOf("192.168")!==-1)ROOT.isDebug=true;
if(!window.console) {window.console = { log: function(msg){} };}
ROOT.isFullScreenDebug=ROOT.isDebug;

import Resize from './module/_resize.webpack.js';
import Layzysizes from './module/_layzysizes.webpack.js';
import Utils from './module/_utils.webpack.js';
import ScrollCommon from './module/_scroll.webpack.js';
import Swipers from './module/_swiper.webpack.js';
import ScrollControll from './module/_scrollControll.webpack.js';
import ModalContent from './module/_modalContent.webpack.js';
import Html from './module/_htmlElement.webpack.js';
import CommonHeader from './module/_header.webpack.js';
import Api from './module/_api.webpack.js';
import RegisterInput from './module/_registerInput.webpack.js';
import ContactInput from './module/_contactInput.webpack.js';
import CreditInput from './module/_creditInput.webpack.js';

ROOT.isTouchDevice = navigator.maxTouchPoints!=0;
ROOT.TE = {
  start: ROOT.isTouchDevice  ? 'touchstart' : 'mousedown',
  move : ROOT.isTouchDevice  ? 'touchmove'  : 'mousemove',
  end  : ROOT.isTouchDevice  ? 'touchend'   : 'mouseup',
  click: 'click',
};

// init
;(function(){

  var urlParaSet = function(){
    var url_search = location.search.substr(1).split('&');
    var para = [];
    // キーエリア初期化
    var key = null;
    for(var i = 0 ; i < url_search.length ; i++){
      key = url_search[i].split("=");
      para[key[0]] = key[1];
    }
    ROOT.urlParaArr = para;
  }
  urlParaSet();



  ROOT.cookieTimeValue = function(string,value,time){
    var coolieTime = 1;
    if (time) {
      coolieTime = time;
    }
    // var date = new Date();
    // date.setTime( date.getTime() + ( 60 * 1000 )); // 60秒
    $.cookie(string, value, { expires: coolieTime, path:"/" });
  }


  ROOT.pageID = $("body").attr("data-pageID");
  ROOT.resize = new Resize();
  ROOT.ev = new EventEmitter();
  ROOT.layzysizes = new Layzysizes();
  ROOT.utils = new Utils();
  ROOT.scrollCommon = new ScrollCommon();
  ROOT.api = new Api();
  ROOT.html = new Html();
  ROOT.swipers = new Swipers();
  ROOT.scrollControll = new ScrollControll();
  ROOT.modalContent = new ModalContent();
  ROOT.commonHeader = new CommonHeader();
  ROOT.registerInput = new RegisterInput();
  ROOT.contactInput = new ContactInput();
  ROOT.creditInput = new CreditInput();

  $(document).ready(()=>{
    $(".wrap").removeClass("standBy")
  });


  window.snsRegistrationError = function(str){
    if (!ROOT.modalContent.isModal) {
      ROOT.modalContent.modalOpen('member-registration')
    }
    ROOT.modalContent.registrationVue.registrationError=true;
    ROOT.modalContent.registrationVue.registrationErrorText=str;
  }

  ROOT.orderChange = function(el){
    var url = location.pathname;
    var para = location.search;
    if (para) {
      if (ROOT.urlParaArr.order) {
        var arr = para.split('order')
        location.href=url+arr[0]+"order="+el.value
      }
      else{
        location.href=url+para+"&order="+el.value
      }
    }
    else{
      location.href=url+"?order="+el.value
    }
  }


})();


Object.assign||Object.defineProperty(Object,"assign",{enumerable:!1,configurable:!0,writable:!0,value:function(e){"use strict";if(null==e)throw new TypeError("Cannot convert first argument to object");for(var r=Object(e),t=1;t<arguments.length;t++){var n=arguments[t];if(null!=n){n=Object(n);for(var c=Object.keys(Object(n)),o=0,a=c.length;o<a;o++){var b=c[o],i=Object.getOwnPropertyDescriptor(n,b);void 0!==i&&i.enumerable&&(r[b]=n[b])}}}return r}});
window.isValidationCheck = false;
window.setcLoading=function(b){
  ROOT.creditVue.sending=b;
}
export default class {
  constructor(){
    var self=this;
    document.addEventListener("DOMContentLoaded", (e)=> {
      if(ROOT.pageID=="credit_input"){
        self.init()
      }
    })
  }
  init(){
    var self=this;
    self.creditData = {};
    self.anyItemList = [];
    $('.uk-input, .uk-select, .uk-radio, .uk-checkbox, .uk-textarea').each((i,el)=>{
      var $el = $(el);
      $el.attr("v-model","creditData."+el.name);
      if ($el.attr('data-any-item')) {
        self.anyItemList.push(el.name);
      }
      if($el.hasClass("uk-checkbox") && !self.creditData[el.name]){self.creditData[el.name]=[]};
      if($el.hasClass("uk-radio") && $el.prop("checked")==false){
        return true;
      }
      else{
        if($el.val()===null){
          self.creditData[el.name]="";
        }else if(typeof self.creditData[el.name]=="object"){
          self.creditData[el.name].push($el.val());
        }else{
          self.creditData[el.name]=$el.val();
        }
      }
    });
    self.setVue()
  }
  setVue(){
    var self=this;
    self.vue = ROOT.creditVue = new Vue({
      delimiters: ['[[',']]'],
      el: "#credit_input",
      data:{
        creditDataResult:{},
        creditData:Object.assign({}, self.creditData),
        anyItemList:self.anyItemList,
        errors:[],
        sending:false,
        creditError:false,
        creditErrorText:'',
      },
      computed:{
      },
      watch:{
      },
      created(){
        var self = this;
        if (ROOT.urlParaArr.creditError) {
          self.creditError = true;
          self.creditErrorText = decodeURI(ROOT.urlParaArr.creditError);
        }
      },
      mounted(){
        var self = this;
      },
      methods:{
        dateCheck(){
          var self = this;
          if (!self.creditData.credit_year) return false;
          if (!self.creditData.credit_month) return false;
          return true;
        },
        checkForm(){
          var self = this;
          var dataKeys = Object.keys(self.creditData);
          self.errors = [];
          for (var i in dataKeys) {
            var itemKey = dataKeys[i]
            var itemVal = self.creditData[itemKey];
            var isAny = false;
            for (var i in self.anyItemList) {
              var anyitem = self.anyItemList[i]
              if (anyitem == itemKey) isAny = true;
            }
            if (!itemVal.length && !isAny) {
              self.errors.push(itemKey);
            }
            else{
              self.creditDataResult[itemKey]=itemVal;
            }
          }
          if (!self.errors.length && !self.validation_email && !self.validation_tel) {
            self.sending = true;
            window.isValidationCheck=true;
            if(window.creditSubmit)window.creditSubmit()
          } else{
            self.sending = false;
            window.isValidationCheck=false;
          }
        }
      }
    })
  }
}


export default class {
  constructor() {
    this.controller = new ScrollMagic.Controller();
    this.scrollTriggers=[];
    this.init();
  }

  init(){
    var self=this;
    self.setAnchorLink();
    $("body").imagesLoaded(function(){
      self.execute();
      $(window).on("load scroll resize",function(){
        var windowH = $(window).height();
        var scrollTop = $(window).scrollTop();
        var wrapHeight = $(".wrap").height();
        var bottomTrigeer = $(".footer").offset().top;
        if (windowH + scrollTop > bottomTrigeer) {
          $(".wrap").addClass("btnHide");
        } else if (windowH + scrollTop < bottomTrigeer) {
          $(".wrap").removeClass("btnHide");
        }
      });
    });
  }
  destroy(){
    this.scrollTriggers.forEach(function(el){
      if(el)el.destroy();
    });
    this.scrollTriggers=[];
  }
  execute(){
    var self=this;
    self.destroy();
    var waitList=[];
    var waitList2=[];
    var re = _.debounce( function(){
      if(waitList2.length){
        waitList=waitList.concat(waitList2);
        waitList2=[];
      }
      waitList=_.sortBy(waitList,function(el){
        return $(el).offset().left*100+$(el).offset().top*1000;
      });

      $(waitList).each(function(i,el){
        var $secTrigger=$(el).parents(".secTrigger")
        if($secTrigger.length==1 && !$secTrigger.hasClass("animEnd")){
          waitList2.push(el);
        }else{
          setTimeout(function(){$(el).addClass("jsShow");},i*50);
        }
      });
      waitList=[];
    }, 40);

    $(".jsStanby").not(".jsShow").each(function(i,el){
      var scene = new ScrollMagic.Scene({
        triggerElement: el,
        triggerHook: 0.9,
      }).on("enter", function (e) {
        if(el){
          waitList.push(el);
          re();
        }
        this.destroy()
      })
      //.addIndicators()
      .addTo(self.controller);
      self.scrollTriggers.push(scene);
    })
  }
  setHorizontalScroll(){
    /* 固定ヘッダーでの横スクロール対応
    *********************************/
    if(!ROOT.isSp){
      var preLeft = 0;
      var $window=$(window);
      var $targets=$("header.header");
      $window.on("scroll", function (e) {
        var left = $window.scrollLeft();
        if (preLeft != left) {
          $targets.css("left", -left);
        }
        preLeft = left;
      });
    }
  }
  setAnchorLink(){
    var self=this;
    $(document).on("click","a[href^='#']",function(e){
      e.preventDefault();
      var id = $(this).attr("href");
      if (id!="#noscroll" && id!="#") {
        self.gotoScroll(id);
        return false;
      }
      else{
        return true;
      }
    });
  }
  gotoScroll(el){
    var header = $(".header").height();
    var searchBar = $(".section-search").height();
    var pos=$(el).length?$(el).offset().top:0;
    $("html,body").animate({scrollTop:pos - (header + searchBar)},800,"easeInOutQuart");
  }
}








Object.assign||Object.defineProperty(Object,"assign",{enumerable:!1,configurable:!0,writable:!0,value:function(e){"use strict";if(null==e)throw new TypeError("Cannot convert first argument to object");for(var r=Object(e),t=1;t<arguments.length;t++){var n=arguments[t];if(null!=n){n=Object(n);for(var c=Object.keys(Object(n)),o=0,a=c.length;o<a;o++){var b=c[o],i=Object.getOwnPropertyDescriptor(n,b);void 0!==i&&i.enumerable&&(r[b]=n[b])}}}return r}});
export default class {
  constructor(){
    document.addEventListener("DOMContentLoaded", (e)=> {
      if(ROOT.pageID=="register_input"){
        this.init()
      }
    })
  }
  init(){
    var self=this;

    self.registerData1 = {}
    self.anyItemList1 = [];
    $('.uk-input, .uk-select, .uk-radio, .uk-checkbox, .uk-textarea','[data-scene=1]').each((i,el)=>{
      var $el = $(el);
      $el.attr("v-model","registerData1."+el.name.replace("[]",""));
      if ($el.attr('data-any-item')) {
        self.anyItemList1.push(el.name.replace("[]",""));
      }
      if($el.hasClass("uk-checkbox") && !self.registerData1[el.name.replace("[]","")]){self.registerData1[el.name.replace("[]","")]=[]};
      if($el.hasClass("uk-radio") && $el.prop("checked")==false){
        return true;
      }else{
        if($el.val()===null){
          self.registerData1[el.name.replace("[]","")]="";
        }else if(typeof self.registerData1[el.name.replace("[]","")]=="object"){
          self.registerData1[el.name.replace("[]","")].push($el.val());
        }else{
          self.registerData1[el.name.replace("[]","")]=$el.val();
        }
      }
    })


    self.registerData2 = {}
    self.anyItemList2 = [];
    $('.uk-input, .uk-select, .uk-radio, .uk-checkbox, .uk-textarea','[data-scene=2]').each((i,el)=>{
      var $el = $(el);
      $el.attr("v-model","registerData2."+el.name.replace("[]",""));
      if ($el.attr('data-any-item')) {
        if (self.anyItemList2.indexOf(el.name.replace("[]","")) == -1) {
          self.anyItemList2.push(el.name.replace("[]",""));
        }
      }
      if($el.hasClass("uk-checkbox") && !self.registerData2[el.name.replace("[]","")]){self.registerData2[el.name.replace("[]","")]=[]};
      if($el.hasClass("uk-radio") && $el.prop("checked")==false){
        return true;
      }else if($el.hasClass("uk-checkbox") && $el.prop("checked")==false){
        return true;
      }else{
        if($el.val()===null){
          self.registerData2[el.name.replace("[]","")]="";
        }else if(typeof self.registerData2[el.name.replace("[]","")]=="object"){
          self.registerData2[el.name.replace("[]","")].push($el.val());
        } else {
          self.registerData2[el.name.replace("[]","")]=$el.val();
        }
      }
    });

    self.valueSearch()
    self.setVue()

  }
  setVue(){
    var self=this;
    self.vue = new Vue({
      delimiters: ['[[',']]'],
      el: "#register_input",
      data:{
        scene:null,
        registerDataResult:{},
        registerData1:Object.assign({}, self.registerData1),
        anyItemList1:self.anyItemList1,
        registerData1Error:[],
        registerData2:Object.assign({}, self.registerData2),
        anyItemList2:self.anyItemList2,
        registerData2Error:[],
        validation_email:false,
        validation_password:false,
        validation_tel:false,
      },
      computed:{
        canSubmit:function(){
          var result=true;
          for( var key in this.registerData1){
            var value = this.registerData1[key];
            if(!value){
              result=false;
              this.registerData1Error[key]=1;
            }
          }
          return result;
        }
      },
      watch:{

      },
      created(){
        var self = this;
      },
      mounted(){
        var self = this;
        if (ROOT.urlParaArr.scene==2) {
          self.scene=ROOT.urlParaArr.scene;
        }
        else{
          self.scene = 1;
        }

        
      console.log(this.registerData1,this.registerData2)
      },
      methods:{
        dateCheck(){
          var self = this;
          if (!self.registerData1.year) return false;
          if (!self.registerData1.month) return false;
          if (!self.registerData1.day) return false;
          return true;
        },
        checkForm(){
          var self = this;
          $('#register_input .register_input_form').submit();
        },
        checkScene1(){
          var self = this;
          var dataKeys = Object.keys(self.registerData1);
          self.registerData1Error = [];
          for (var i in dataKeys) {
            var itemKey = dataKeys[i]
            var itemVal = String(self.registerData1[itemKey]);
            var isAny = false;
            for (var i in self.anyItemList1) {
              var anyitem = self.anyItemList1[i]
              if (anyitem == itemKey) isAny = true;
            }
            if (!itemVal.length && !isAny) {
              self.registerData1Error.push(itemKey);
            }
            else{
              self.registerDataResult[itemKey]=itemVal;
            }
          }
          if (!self.registerData1Error.length && !self.validation_password && !self.validation_tel) {
            console.log(self.registerDataResult)
            self.nextScene();
          }
        },
        checkScene2(){
          var self = this;
          var dataKeys = Object.keys(self.registerData2);
          self.registerData2Error = [];
          for (var i in dataKeys) {
            var itemKey = dataKeys[i]
            var itemVal = self.registerData2[itemKey];
            var isAny = false;
            for (var i in self.anyItemList2) {
              var anyitem = self.anyItemList2[i]
              if (anyitem == itemKey) isAny = true;
            }
            if (!itemVal.length && !isAny) {
              self.registerData2Error.push(itemKey);
            }
            else{
              self.registerDataResult[itemKey]=itemVal;
            }
          }
          if (!self.registerData2Error.length) {
            self.checkForm();
          }
        },
        nextScene(){
          var self = this;
          self.scene += 1;
          $( document ).scrollTop(0);
        },
        backScene(){
          var self = this;
          self.scene -= 1;
          $( document ).scrollTop(0);
        },
        searchAddress(){
          var self = this;
          new YubinBango.Core(self.registerData1.postal_code, function(addr) {
            self.registerData1.prefecture  = addr.region_id // 都道府県ID
            self.registerData1.city = addr.locality  // 市区町村
            self.registerData1.address   = addr.street　  // 町域
          })
        },
        selectChange(event){
          //$(event.target).addClass('uk-select-selected');
        },
        passwordCheck(){
          var self = this;
          if (ROOT.utils.validationPassword(self.registerData1.password)) {
            self.validation_password = false;
          }
          else{
            self.validation_password = true;
          }
        },
        telCheck(){
          var self = this;
          if (ROOT.utils.validationTel(self.registerData1.tel)) {
            self.validation_tel = false;
          }
          else{
            self.validation_tel = true;
          }
        }

      }
    })
  }
  valueSearch(){
    var self=this;
  }
}


export default class {
  constructor(){
    this.$body=$("body");
    this.navShow = null;
    this.$window=$(window);
    this.init()
  }
  init(){
    var self=this;
    self.isOpen = false;

    $(document).on("click",".btn-navigation-toggle",function(e){
      e.preventDefault();
      if ($("body").hasClass("navOpen")) {
        self.close();
      }
      else{
        self.open();
      }
      //return false;
    });
    var navClone = $(".header .navigation").clone().addClass('sp-navigation');
    $('.header').after(navClone)

  }



  open(){
    this.isOpen = true;
    $("body").removeClass("navClose").addClass("navOpen")
    ROOT.scrollControll.stop()
  }
  close(){
    this.isOpen = false;
    this.$body.removeClass("navOpen navShow navHide").addClass("navClose")
    ROOT.scrollControll.move()
  }
  spSetNav(){
    var self=this;

  }
  pcSetNav(){
    var self=this;

  }

}


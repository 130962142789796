Object.assign||Object.defineProperty(Object,"assign",{enumerable:!1,configurable:!0,writable:!0,value:function(e){"use strict";if(null==e)throw new TypeError("Cannot convert first argument to object");for(var r=Object(e),t=1;t<arguments.length;t++){var n=arguments[t];if(null!=n){n=Object(n);for(var c=Object.keys(Object(n)),o=0,a=c.length;o<a;o++){var b=c[o],i=Object.getOwnPropertyDescriptor(n,b);void 0!==i&&i.enumerable&&(r[b]=n[b])}}}return r}});
export default class {
  constructor(){
    var self=this;
    document.addEventListener("DOMContentLoaded", (e)=> {
      if(ROOT.pageID=="contact_input"){
        self.init()
      }
    })
  }
  init(){
    var self=this;
    self.contactData = {};
    self.anyItemList = [];
    $('.uk-input, .uk-select, .uk-radio, .uk-checkbox, .uk-textarea','.contact_input_form').each((i,el)=>{
      var $el = $(el);
      $el.attr("v-model","contactData."+el.name);
      if ($el.attr('data-any-item')) {
        self.anyItemList.push(el.name);
      }
      if($el.hasClass("uk-checkbox") && !self.contactData[el.name]){self.contactData[el.name]=[]};
      if($el.hasClass("uk-radio") && $el.prop("checked")==false){
        return true;
      }
      else{
        if($el.val()===null){
          self.contactData[el.name]="";
        }else if(typeof self.contactData[el.name]=="object"){
          self.contactData[el.name].push($el.val());
        }else{
          self.contactData[el.name]=$el.val();
        }
      }
    });
    self.setVue()
  }
  setVue(){
    var self=this;
    self.vue = new Vue({
      delimiters: ['[[',']]'],
      el: "#contact_input",
      data:{
        contactDataResult:{},
        contactData:Object.assign({}, self.contactData),
        anyItemList:self.anyItemList,
        notEntered:true,
        validation:true,
        errors:[],
        validation_email:false,
        validation_tel:false,
      },
      computed:{
      },
      watch:{
      },
      created(){
        var self = this;
      },
      mounted(){
        var self = this;
      },
      methods:{
        checkForm(){
          var self = this;
          var dataKeys = Object.keys(self.contactData);
          self.errors = [];
          for (var i in dataKeys) {
            var itemKey = dataKeys[i]
            var itemVal = self.contactData[itemKey];
            var isAny = false;
            for (var i in self.anyItemList) {
              var anyitem = self.anyItemList[i]
              if (anyitem == itemKey) isAny = true;
            }
            if (!itemVal.length && !isAny) {
              self.errors.push(itemKey);
            }
            else{
              self.contactDataResult[itemKey]=itemVal;
            }
          }
          if (!self.errors.length && !self.validation_email && !self.validation_tel) {
            $('#contact_input .contact_input_form').submit();
          }
        },
        emailCheck(){
          var self = this;
          if (ROOT.utils.validationEmail(self.contactData.email)) {
            self.validation_email = false;
          }
          else{
            self.validation_email = true;
          }
        },
        telCheck(){
          var self = this;
          if (ROOT.utils.validationTel(self.contactData.tel)) {
            self.validation_tel = false;
          }
          else{
            self.validation_tel = true;
          }
        },
      }
    })
  }
}


export default class {
  constructor(){
    this.init();
  }
  init(){
    lazySizes.init();
  }
  destroy(){

  }
  excute(){

  }
}

export default class {
  constructor(){
    var self = this;
    this.init();
  }
  init(){
    var self = this;
  }
  validationEmail(str){
    var regexp = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
    return regexp.test(str);
  }
  validationPassword(str){
    str = (str==null)?"":str;
    if (str.length < 8) {
      return false;
    }
    if(str.match(/^[A-Za-z0-9]*$/)){
      return true;
    }else{
      return false;
    }
  }
  validationTel(str){
    var tel = str.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi,'')
    if (!tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/)) {
      return false;
    }
    else{
      return true;
    }
  }
}
export default class {
  constructor(){
    var self=this;
    self.init();
  }
  init(){
    var self=this;
    self.$wrap=$(".wrap");
    self.current_scrollY=0;
    self.$scrollTarget=$( document );
  }
  wrapStyle(){
    var self=this;
    self.$wrap.css({
      position: "fixed",
      left: 0,
      top: -1 * self.current_scrollY
    });
  }
  stop(){
    var self=this;
    ROOT.ev.emit("ON_MODAL","OPEN");
    self.isWrapStop = true;
    if(!self.current_scrollY)self.current_scrollY = self.$scrollTarget.scrollTop();
    self.wrapStyle();
    /**
    if ($('html.Safari').length) {
      setTimeout(function(){ self.wrapStyle(); },100);
    }else{
      self.wrapStyle();
    }**/
    setTimeout(function(){
      self.$scrollTarget.scrollTop(0);
    },100)
  }
  move(){
    var self=this;
    self.$wrap.removeAttr("style");
    self.isWrapStop = false;
    $( document ).scrollTop(self.current_scrollY);
    self.current_scrollY=null;
    ROOT.ev.emit("ON_MODAL","CLOSE");

  }
}

